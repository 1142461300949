import axios from 'axios'

export default {
  namespaced: true,
  state: {
    stepIndex: null,
    items: [
      {
        title: 'Customize the look and feel of your job board',
        steps: [
          {
            title: 'Name your job board',
            description:
              'Set a name to be displayed throughout job board and in emails.',
            complete: false,
            routeAndElement: {
              route: '/preferences/appearance',
              element: '#display-name-section',
            },
            key: 'name_your_job_board',
          },
          {
            title: 'Choose a brand color',
            description:
              'Select a brand color to automatically theme your job board.',
            complete: false,
            routeAndElement: {
              route: '/preferences/appearance',
              element: '#brand-color-section',
            },
            key: 'choose_a_brand_color',
          },
          {
            title: 'Upload your logo',
            description:
              'Upload a memorable logo that represents your job board.',
            complete: false,
            routeAndElement: {
              route: '/preferences/appearance',
              element: '#logo-section',
            },
            key: 'upload_your_logo',
          },
          {
            title: 'Upload your favicon',
            description:
              'Upload a memorable logo that represents your job board.',
            complete: false,
            routeAndElement: {
              route: '/preferences/appearance',
              element: '#favicon-section',
            },
            key: 'upload_your_favicon',
          },
          {
            title: 'Set a hero text',
            description:
              'Describe what your job board is all about in 1 or 2 sentences.',
            complete: false,
            routeAndElement: {
              route: '/preferences/appearance',
              element: '#hero-text-section',
            },
            key: 'set_a_hero_text',
          },
          {
            title: 'Add custom link',
            description: 'Point a domain name you own to your new job board.',
            complete: false,
            routeAndElement: {
              route: '/preferences/general',
              element: '#custom-domain-section',
            },
            key: 'add_custom_link',
          },
        ],
      },
      // {
      //   title: 'Invite your teammates to explore Job Board Fire',
      //   steps: [
      //     {
      //       title: 'Create a role',
      //       complete: false,
      //       routeAndElement: {
      //         route: '/preferences/team/roles',
      //       },
      //       key: 'create_a_role',
      //     },
      //     {
      //       title: 'Invite your first team member to join',
      //       complete: false,
      //       routeAndElement: {
      //         route: '/preferences/team/users/new',
      //       },
      //       key: 'invite_your_first_team_member_to_join',
      //     },
      //   ],
      // },
      {
        title: 'Customize the search experience',
        steps: [
          {
            title: 'Create a category',
            complete: false,
            routeAndElement: {
              route: '/preferences/categories',
              element: '#defined-categories',
            },
            key: 'create_a_category',
          },
          {
            title: 'Enable tags',
            complete: false,
            routeAndElement: {
              route: '/preferences/categories',
              element: '#enable-tags',
            },
            key: 'enable_tags',
          },
          {
            title: 'Create a new searchable job post field',
            description:
              'Select a brand color to automatically theme your job board.',
            complete: false,
            routeAndElement: {
              route: '/preferences/fields/job-post-fields',
            },
            key: 'create_a_new_searchable_job_post_field',
          },
        ],
      },
      {
        title: 'Monetize your job board',
        steps: [
          {
            title: 'Connect your Stripe account to accept payments',
            routeAndElement: {
              route: '/preferences/billing',
            },
            complete: false,
            key: 'connect_your_stripe_account_to_accept_payments',
          },
          {
            title: 'Create a paid job post product',
            complete: false,
            routeAndElement: {
              route: '/preferences/products/new',
            },
            key: 'create_a_paid_job_post_product',
          },
          {
            title: 'Add an upsell to your product',
            complete: false,
            routeAndElement: {
              route: '/preferences/products',
            },
            key: 'add_an_upsell_to_your_product',
          },
          {
            title: 'Create a paid multi job post product',
            complete: false,
            routeAndElement: {
              route: '/preferences/products/new',
            },
            key: 'create_a_paid_multi_job_post_product',
          },
        ],
      },
      {
        title: 'Populate your job board',
        steps: [
          {
            title: 'Create your first company',
            routeAndElement: {
              route: '/companies/new/profile',
            },
            complete: false,
            key: 'create_your_first_company',
          },
          {
            title: 'Create your first job post',
            complete: false,
            routeAndElement: {
              route: '/jobs/new/job',
            },
            key: 'create_your_first_job_post',
          },
        ],
      },
      {
        title: 'Launch your job board',
        steps: [
          {
            title: 'Connect your domain name',
            complete: false,
            routeAndElement: {
              route: '/preferences/general',
              element: '#custom-domain-section',
            },
            key: 'connect_your_domain_name',
          },
        ],
      },
    ],
  },
  getters: {
    getSteps: (state) =>
      state.items.map((item) => {
        item.steps.sort((a, b) => {
          if (a.complete && !b.complete) {
            return -1
          }

          if (b.complete && !a.complete) {
            return 1
          }

          return 0
        })
        return item
      }),
    setupProgress: (state) => {
      const arr = state.items.map((item) => {
        const completeCount = item.steps.reduce((completeCount, item) => {
          if (item.complete) {
            return completeCount + 1
          }

          return completeCount
        }, 0)

        return (completeCount / item.steps.length) * 100
      })
      return arr.reduce((prev, curr) => prev + curr) / arr.length
    },
    setupProgressStep: (state) => {
      const item = state.items[state.stepIndex]
      const completeCount = item.steps.reduce((completeCount, item) => {
        if (item.complete) {
          return completeCount + 1
        }

        return completeCount
      }, 0)

      return (completeCount / item.steps.length) * 100
    },
    isCompleted: (state, getters) => {
      return getters.setupProgress === 100
    },
  },
  mutations: {
    setPassedSteps(state, payload) {
      payload.forEach(({ onboardingStepKey }) => {
        state.items.forEach(({ steps }) => {
          steps.forEach((step) => {
            if (step.key === onboardingStepKey) {
              step.complete = true
            }
          })
        })
      })
    },
    setPassStep(state, payload) {
      state.items.forEach(({ steps }) => {
        steps.forEach((step) => {
          if (step.key === payload.OnboardingStepKey) {
            step.complete = true
          }
        })
      })
    },
    toggleSteps(state, index = null) {
      state.stepIndex = index
    },
  },
  actions: {
    fetchPassedSteps({ commit }) {
      return axios.get('/Operator/Onboarding/GetPassedSteps').then((res) => {
        commit('setPassedSteps', res.data)
      })
    },
  },
}
