<template>
  <router-view />
</template>
<script>
export default {
  metaInfo: {
    titleTemplate: '%s - Job Board Fire',
  },
}
</script>
