import { mapGetters } from 'vuex'

export const currencyFormat = {
  usd: { prefix: '$', suffix: '' },
  eur: { prefix: '€', suffix: '' },
  gbp: { prefix: '£', suffix: '' },
  aud: { prefix: '$', suffix: '(AUD)' },
  ars: { prefix: '$', suffix: '(ARS)' },
  cad: { prefix: '$', suffix: '(CAD)' },
  chf: { prefix: 'CHF', suffix: '' },
  hkd: { prefix: 'HK$', suffix: '' },
  nzd: { prefix: '$', suffix: '(NZD)' },
  sek: { prefix: '', suffix: 'kr' },
  sgd: { prefix: 'S$', suffix: '' },
  mxn: { prefix: '', suffix: '(MXN)' },
  inr: { prefix: '₹', suffix: '' },
  rub: { prefix: '', suffix: '₽' },
  zar: { prefix: 'R', suffix: '' },
  brl: { prefix: 'R$', suffix: '' },
  twd: { prefix: '$', suffix: '(TWD)' },
  dkk: { prefix: '', suffix: 'kr.' },
  pln: { prefix: '', suffix: 'zł' },
  idr: { prefix: 'Rp', suffix: '' },
  huf: { prefix: '', suffix: 'Ft' },
  czk: { prefix: '', suffix: 'Kč' },
  try: { prefix: '₺', suffix: '' },
  qar: { prefix: 'QAR', suffix: '' },
}

export const currencyLocales = {
  usd: 'en-US',
  eur: 'de-DE',
  gbp: 'en-GB',
  aud: 'en-AU',
  ars: 'es-AR',
  cad: 'en-CA',
  chf: 'de-CH',
  hkd: 'en-HK',
  nzd: 'en-NZ',
  sek: 'sv-SE',
  sgd: 'en-SG',
  mxn: 'es-MX',
  inr: 'en-IN',
  rub: 'ru-RU',
  zar: 'en-ZA',
  brl: 'pt-BR',
  twd: 'zh-Hant-TW',
  dkk: 'da-DK',
  pln: 'pl-PL',
  idr: 'id-ID',
  huf: 'hu-HU',
  czk: 'cs-CZ',
  try: 'tr-TR',
  qar: 'en-QA',
}

export const getPriceString = {
  data: () => ({
    currencyLocales,
  }),
  computed: {
    ...mapGetters(['getEcommercePreferences']),
  },
  methods: {
    getCurrencyFormat(currency) {
      return currencyFormat[currency]
    },
    getPriceString(price, currency, locale) {
      const priceFloat = parseFloat(price)
      currency =
        currency ||
        this.getEcommercePreferences?.currency ||
        this.$store.state.jobBoard.currency
      return this.formatCurrency(priceFloat, currency, locale)
    },

    formatCurrency(value, currency, locale) {
      locale = locale || this.currencyLocales[currency]

      return value.toLocaleString(locale, { style: 'currency', currency })
    },
  },
}
