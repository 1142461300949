<template>
  <div class="c-list d-flex flex-column white pa-1">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'list',
}
</script>

<style>
.c-list {
  gap: 4px 0;
}
</style>
