<template>
  <ModalWrapper v-click-outside="hide" class="px-2 pt-4">
    <h6
      class="text-subtitle-2 pb-3 text-center font-weight-medium"
      style="color: #30333d"
    >
      Quickstart
    </h6>
    <div class="progressModal__bar home-page__bar mb-5 mx-auto">
      <v-progress-linear
        class="progressModal__bar__line my-0"
        height="4"
        :value="setupProgress"
        background-color="#C9CFD9"
      ></v-progress-linear>
    </div>

    <HomeProgressCard
      v-for="(item, i) in items"
      :key="i"
      @click="watchSteps(i)"
      v-bind="item"
      :is-home="false"
      class="progressModal__step"
    />
  </ModalWrapper>
</template>

<script>
import HomeProgressCard from '@/components/Home/HomeProgressCard'
import { mapGetters } from 'vuex'
import ModalWrapper from '@/components/AccountProgressModal/ModalWrapper'

export default {
  name: 'AccountProgressModal',

  components: { ModalWrapper, HomeProgressCard },

  computed: {
    ...mapGetters({
      items: 'progress/getSteps',
      setupProgress: 'progress/setupProgress',
    }),
  },

  methods: {
    watchSteps(index) {
      this.$store.commit('progress/toggleSteps', index)
    },

    hide() {
      this.$emit('hide')
    },
  },
}
</script>

<style>
.progressModal__bar {
  width: 146px;
  padding: 3px;
  border-radius: 20px;
  border: 1px solid #3564ff;
  background: rgba(53, 100, 255, 0.1);
}
.progressModal__bar__line {
  border-radius: 20px;
  background: transparent;
}
.progressModal__bar__line .v-progress-linear__background {
  background: transparent !important;
}
.progressModal__bar__line .v-progress-linear__determinate {
  border-radius: 20px !important;
}
.progressModal__step {
  outline: 1px solid transparent;
  transition: all 0.3s;
  cursor: pointer;
}
.progressModal__step:last-of-type {
  margin-bottom: 0 !important;
}
.progressModal__step:hover {
  outline: 1px solid #3564ff;
}
</style>
