<template>
  <ModalWrapper v-click-outside="hide">
    <button
      @click="back"
      class="progressStepModal__header d-flex align-center px-3 mb-3 text-left"
    >
      <v-icon class="mr-2" dense>chevron_left</v-icon>
      <h6 class="progressStepModal__header__title font-weight-medium">
        {{ item.title }}
      </h6>
    </button>
    <v-progress-linear
      class="homeProgressCard__bar mt-0 mb-3"
      height="4"
      :value="setupProgressStep"
      color="#2BC769"
    />

    <div class="px-3">
      <HomeProgressCardStep
        v-for="(step, i) in item.steps"
        :key="i"
        :title="step.title"
        :done="step.complete"
        title-size="small"
        :is-home="false"
        class="progressStepModal__step mb-1 px-1 cursor-pointer"
        @click.native="
          step.complete ? null : goToRouteAndElement(step.routeAndElement)
        "
      />
    </div>
  </ModalWrapper>
</template>

<script>
import { mapGetters } from 'vuex'
import ModalWrapper from '@/components/AccountProgressModal/ModalWrapper'
import HomeProgressCardStep from '@/components/Home/HomeProgressCardStep'
import { EventBus } from '@/util/eventBus'

export default {
  name: 'AccountProgressStepsModal',

  components: { HomeProgressCardStep, ModalWrapper },

  props: {
    stepIndex: {
      type: Number,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      items: 'progress/getSteps',
      setupProgressStep: 'progress/setupProgressStep',
    }),

    item() {
      return this.items[this.stepIndex]
    },
  },

  methods: {
    hide() {
      this.$emit('hide')
      this.back()
    },

    goToRouteAndElement(routeAndElement) {
      EventBus.$emit('goToRouteAndElement', routeAndElement)
      this.hide()
    },

    back() {
      this.$store.commit('progress/toggleSteps')
    },
  },
}
</script>

<style>
.progressStepModal__header__title {
  font-size: 14px !important;
  line-height: 16px;
  color: #30333d;
}
.progressStepModal__step {
  border: 1px solid transparent;
  border-radius: 4px;
  transition: all 0.3s;
}
.progressStepModal__step:hover {
  border: 1px solid #3564ff;
}
</style>
