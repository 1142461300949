export default async function ({ next, store }) {
  if (!store.getters.getJobBoard) {
    await store.dispatch('fetchJobBoard')
  }

  if (!store.getters.getJobBoard.jobAggregationFeatureEnabled) {
    return next('/preferences/backfill')
  }
  return next()
}
