<template>
  <div class="homeProgressCardStep">
    <div class="d-flex align-center">
      <div :class="isHome ? 'mr-5' : 'mr-2'">
        <v-icon :color="iconColor" :small="!isHome">check_circle</v-icon>
      </div>
      <p
        class="homeProgressCardStep__title font-weight-medium mb-0"
        :class="`homeProgressCardStep__title-${titleSize}`"
      >
        {{ title }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeProgressCardStep',
  props: {
    done: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    titleSize: {
      type: String,
      default: 'high',
      validator: (val) => ['high', 'medium', 'small'].includes(val),
    },
    isHome: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    iconColor() {
      return this.done ? '#2BC769' : '#D6D9DD'
    },
  },
}
</script>

<style>
.homeProgressCardStep {
  padding: 8px 10px;
}
.homeProgressCardStep__title {
  color: #30333d;
}
.homeProgressCardStep__title-high {
  font-size: 18px !important;
}
.homeProgressCardStep__title-medium {
  font-size: 16px !important;
}
.homeProgressCardStep__title-small {
  font-size: 14px !important;
  line-height: 16px;
}
</style>
