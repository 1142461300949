export default function configureWindowEnv() {
  window.windowEnv = {}
  const hostname = window && window.location && window.location.hostname

  if (/^.*localhost.*/.test(hostname)) {
    window.windowEnv.API_BASE = 'http://localhost:5000/api/'
    window.windowEnv.STRIPE_REDIRECT_URL =
      'http://localhost:8080/connect-stripe'
    window.windowEnv.STRIPE_CLIENT_ID = 'ca_Lx5Th4lmYYvufj7Z5xbyO6nZMLqmcC67'
    window.windowEnv.S3_BASE_URL =
      'https://job-board-fire-dev.s3.amazonaws.com/'
    window.windowEnv.CUSTOM_S3_BASE_URL =
      'https://job-board-fire-custom-dev.s3.amazonaws.com/'
  } else {
    window.windowEnv.API_BASE = 'https://growatliberty.xyz/api/'
    window.windowEnv.STRIPE_REDIRECT_URL =
      'https://my.growatliberty.xyz/connect-stripe'
    window.windowEnv.STRIPE_CLIENT_ID = 'ca_DcmBEkNvayVzJ05zuxX832qVg0P8LTIg'
    window.windowEnv.S3_BASE_URL = 'https://d3pgq7fhdc5jrl.cloudfront.net/'
    window.windowEnv.CUSTOM_S3_BASE_URL =
      'https://dyvot1n3r73kt.cloudfront.net/'
  }
}
