<template>
  <v-combobox
    ref="input"
    class="solo-flat-outline"
    :loading="isLoading"
    :search-input.sync="search"
    :items="options"
    :label="label"
    :item-text="itemText"
    @change="handleChange"
    @blur="onBlur"
    @focus="onFocus"
    :value="value"
    return-object
    no-filter
    :rules="rules"
    :hide-details="hideDetails"
    :disabled="disabled"
    v-bind="$attrs"
  ></v-combobox>
</template>

<script>
import debounce from '@/util/debounce'

export default {
  name: 'api-auto-complete',
  props: {
    disabled: Boolean,
    hideDetails: Boolean,
    rules: Array,
    label: String,
    itemText: {
      type: Function,
      default: () => null,
    },
    fetch: Function,
    value: [Object, String],
    onlySelect: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isLoading: false,
    options: [],
    search: '',
  }),
  methods: {
    onBlur() {
      if (this.onlySelect) {
        this.search = this.itemText(this.value)
      }
    },
    onFocus() {
      // Vuetify selects input text on focus
      // We remove the selected text on focus
      this.$nextTick(() => {
        const sel = window.getSelection
          ? window.getSelection()
          : document.selection
        if (sel) {
          if (sel.removeAllRanges) {
            sel.removeAllRanges()
          } else if (sel.empty) {
            sel.empty()
          }
        }
      })
    },
    handleChange(value) {
      if (this.onlySelect && value !== this.search) {
        this.$emit('change', value)
      }

      this.$emit('click', value)
    },
    fetchDebounced: debounce(function () {
      if (!this.search) {
        this.isLoading = false
        return
      }

      this.fetch(this.search)
        .then((options) => {
          this.options = options
        })
        .finally(() => {
          this.isLoading = false
        })
    }, 500),
  },
  watch: {
    search(value) {
      if (this.search && this.search !== this.value) {
        this.isLoading = true

        this.fetchDebounced()
      }

      if (!this.onlySelect && value !== null) {
        this.$emit('change', value)
      }
    },
  },
}
</script>
