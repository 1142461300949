import { PAGE_ACCESS } from '@/constants/access'

export default async function ({ next, store }) {
  const page = getAvailablePage(store)

  if (!store.getters.getJobBoard) {
    await store.dispatch('fetchJobBoard')
  }

  if (store.getters['progress/isCompleted'] && page) {
    return next(page)
  }
  return next()
}

function getAvailablePage(store) {
  return [
    '/financials',
    '/jobs',
    '/companies',
    '/users',
    '/alerts',
    '/preferences',
  ].find((page) =>
    PAGE_ACCESS[page]?.some((key) =>
      store.getters.getAccessRights.includes(key)
    )
  )
}
