<template>
  <div>
    <v-select
      solo
      flat
      append-icon="arrow_drop_down"
      class="jobBoard__switch solo-flat-outline ml-4"
      item-value="jobBoardId"
      item-text="jobBoardId"
      :value="id"
      :items="getJobBoards"
      @change="handleChange"
      hide-details
      ref="select"
    >
      <template v-slot:append-item v-if="shouldCreateBoard">
        <v-divider class="mb-2"></v-divider>

        <btn color="primary" small @click="handleModal">
          <v-icon x-small class="pr-2">add</v-icon>
          New Job Board (14 Day Trial)
        </btn>
      </template>
      <template v-slot:append-item v-else>
        <v-divider class="mb-2"></v-divider>
        <div class="pa-2">
          <p class="mb-0"><strong>Need another job board?</strong></p>
          <p class="mb-0">Please get in touch.</p>
        </div>
      </template>
    </v-select>

    <create-jobboard-modal
      :is-open="isOpen"
      :loading="loading"
      :errors="errors"
      v-model="jobBoardId"
      @close-modal="handleModalClose"
      @clear-errors="clearErrors"
      @create="handleCreate"
    >
    </create-jobboard-modal>

    <v-snackbar v-model="hasCreated" color="success" :timeout="2000" top>
      Successfully created
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CreateJobBoardModal from '@/components/NavBar/JobBoardSwitch/CreateModal'

export default {
  name: 'job-board-switch',
  props: {
    id: String,
  },
  components: {
    'create-jobboard-modal': CreateJobBoardModal,
  },
  data: () => ({
    isOpen: false,
    jobBoardId: '',
    loading: false,
    errors: [],
    hasCreated: false,
  }),
  computed: {
    ...mapGetters(['getJobBoards']),
    shouldCreateBoard() {
      return this.$auth.user().freeTrialCredits
    },
  },
  methods: {
    handleCreate() {
      this.loading = true

      this.$http
        .post(
          '/Operator/RegisterJobBoardOnly',
          {
            jobBoardId: this.jobBoardId,
          },
          { headers: { jobBoardId: 'dashboard' } }
        )
        .then(() => {
          this.hasCreated = true
          this.isOpen = false

          this.handleChange()
        })
        .catch(() => {
          this.errors = ['Something went wrong, please try a different name']
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleChange(value) {
      this.$nextTick(() => {
        location.href = '/'
      })

      localStorage.setItem('jobBoardId', value)
    },
    handleModal() {
      this.$refs.select.blur()

      this.isOpen = true
    },

    handleModalClose() {
      this.isOpen = false
    },

    clearErrors() {
      this.errors = []
    },
  },
}
</script>

<style>
.jobBoard__switch {
  margin-top: 0 !important;
  max-width: 180px;
}

.jobBoard__switch .v-select__selections {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.jobBoard__switch .v-input__control {
  min-height: 40px !important;
}
</style>
