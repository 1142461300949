<template>
  <v-card>
    <btn
      href="https://feedback.jobboardfire.com/changelog"
      target="_blank"
      text
      plain
      :ripple="false"
    >
      View all updates
    </btn>

    <div class="changeLog__list">
      <v-list-item
        v-for="update in changeLogs"
        :key="update.title"
        :href="update.url"
        target="_blank"
      >
        <v-list-item-content>
          <v-list-item-subtitle>
            {{ $moment.utc(update.date, 'YYYY-MM-DD h:mm A').fromNow() }}
          </v-list-item-subtitle>
          <v-list-item-title class="text-wrap">
            {{ update.title }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'ChangeLog',
  props: {
    changeLogs: Array,
  },
}
</script>

<style>
.changeLog__list .v-list-item:first-child {
  padding-top: 0 !important;
}
</style>
