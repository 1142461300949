<template>
  <v-btn
    plain
    :ripple="false"
    :class="getClass"
    v-bind="$attrs"
    v-on="$listeners"
    :color="color"
    :disabled="disabled"
  >
    <slot />
  </v-btn>
</template>

<script>
import { VBtn } from 'vuetify'
const STANDART_COLORS = ['error', 'success', 'primary']

export default {
  name: 'Btn',
  props: {
    xSmall: Boolean,
    small: Boolean,
    medium: {
      type: Boolean,
      default: true,
    },
    large: Boolean,
    text: Boolean,
    outlined: Boolean,
    default: Boolean,
    color: String,
    disabled: Boolean,
  },
  components: {
    VBtn,
  },
  computed: {
    getColorClass() {
      if (this.disabled && STANDART_COLORS.includes(this.color)) {
        return `c-btn--${this.color}`
      }
    },

    getTypeClass() {
      const sizes = [this.default, this.text, this.outlined]
      const size = sizes.findIndex(Boolean)

      const map = {
        0: 'c-btn--default',
        1: 'c-btn--text',
        2: 'c-btn--outlined',
      }

      return map[size]
    },

    getSizeClass() {
      const sizes = [this.xSmall, this.small, this.medium, this.large]
      const size = sizes.findIndex(Boolean)

      const map = {
        0: 'c-btn--x-small',
        1: 'c-btn--small',
        2: 'c-btn--medium',
        3: 'c-btn--large',
      }

      return map[size]
    },
    getClass() {
      return ['c-btn', this.getSizeClass, this.getTypeClass, this.getColorClass]
    },
  },
}
</script>

<style>
.c-btn:not(.v-btn--block) {
  min-width: 0 !important;
}

.c-btn--x-small {
  font-weight: 500;
  font-size: 12px !important;
  line-height: 14px;
  border-radius: 4px;
  padding: 7px 12px !important;
}

.c-btn--small {
  font-weight: 500;
  font-size: 14px !important;
  line-height: 16px;
  border-radius: 4px;
  padding: 8px 16px !important;
}

.c-btn--medium {
  font-weight: 500;
  font-size: 14px !important;
  line-height: 16px;
  border-radius: 4px;
  padding: 10px 16px !important;
}

.c-btn--large {
  font-weight: 500;
  font-size: 16px !important;
  line-height: 19px;
  border-radius: 6px;
  padding: 11px 16px !important;
}

.c-btn--default {
  background: currentColor;
  box-shadow: 0px 0px 0px 0px #00000000, 0px 0px 0px 0px #00000000,
    0px 1px 1px 0px #0000002c, 0px 0px 0px 1px currentColor,
    0px 0px 0px 0px #00000000, 0px 0px 0px 0px #00000000,
    0px 2px 5px 0px #40445214;
}

.c-btn--default .v-btn__content {
  color: #fff;
}

.c-btn--default:hover:after {
  content: '';
  background: rgb(0 0 0 / 8%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.c-btn--outlined {
  background: #fff;
  color: currentColor;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.12),
    0px 0px 0px 1px rgba(64, 68, 82, 0.16), 0px 2px 5px rgba(64, 68, 82, 0.08);
}

.c-btn--outlined:hover:after,
.c-btn--text:hover:after {
  content: '';
  background: rgb(0 0 0 / 8%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.c-btn--text {
  color: currentColor;
}

.c-btn .v-btn__content {
  z-index: 1;
  max-width: 100%;
}

.c-btn:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(:hover)
  .v-btn__content {
  opacity: 1;
}

.c-btn.theme--light.v-btn.v-btn--disabled {
  opacity: 0.5;
}

.c-btn i.v-icon.notranslate.material-icons.theme--light {
  color: currentColor !important;
}

.c-btn--error.theme--light.v-btn.v-btn--disabled {
  color: #ff5252 !important;
}

.c-btn--primary.theme--light.v-btn.v-btn--disabled {
  color: #3564ff !important;
}

.c-btn.v-btn:not(.v-btn--round).v-size--default {
  height: auto;
}

.c-btn--default .v-btn__loader {
  color: #fff;
}

/* Icon size */
.c-btn--small .v-btn__content i {
  font-size: 12px;
}

.c-btn--small .v-btn__content i {
  font-size: 14px;
}

.c-btn--medium .v-btn__content i {
  font-size: 14px;
}

.c-btn--large .v-btn__content i {
  font-size: 16px;
}
</style>
