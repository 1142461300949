<template>
  <v-card
    v-on="$listeners"
    v-click-outside="hide"
    class="homeProgressCard pa-0"
    :class="isHome ? 'mb-5' : 'mb-4'"
  >
    <v-progress-linear
      class="homeProgressCard__bar my-0"
      height="4"
      :value="setupProgress"
      color="#2BC769"
    />
    <div
      @click="toggle"
      class="homeProgressCard__header d-flex align-center justify-space-between cursor-pointer"
      :class="{ 'py-1 px-0': !isHome }"
    >
      <div class="d-flex align-center">
        <HomeProgressCardStep
          :title="title"
          :done="completed"
          :title-size="isHome ? 'high' : 'small'"
          :is-home="isHome"
        />
        <div
          v-if="!completed && isHome"
          class="homeProgressCard__header__count ml-3 font-weight-medium"
        >
          {{ stepText }}
        </div>
      </div>
      <v-icon
        v-if="!completed && isHome"
        large
        class="homeProgressCard__header__arrow"
        :class="{ 'homeProgressCard__header__arrow-active': show }"
      >
        expand_more
      </v-icon>
    </div>
    <SlideUpDown v-if="isHome" :active="show" :duration="300">
      <div class="homeProgressCard__body px-5 py-3">
        <div
          v-for="step in steps"
          :key="step.title"
          class="homeProgressCard__step d-flex align-center justify-space-between mb-3 cursor-pointer"
          @click="
            step.complete ? null : goToRouteAndElement(step.routeAndElement)
          "
        >
          <HomeProgressCardStep
            :title="step.title"
            :done="step.complete"
            title-size="medium"
          />
          <v-icon large>navigate_next</v-icon>
        </div>
      </div>
    </SlideUpDown>
  </v-card>
</template>

<script>
import HomeProgressCardStep from '@/components/Home/HomeProgressCardStep'
import SlideUpDown from 'vue-slide-up-down'
import { EventBus } from '@/util/eventBus'

export default {
  name: 'HomeProgressCard',

  components: { HomeProgressCardStep, SlideUpDown },

  props: {
    title: {
      type: String,
      default: 'Sign up for Job Board Fire',
    },
    steps: {
      type: Array,
      default: () => [],
    },
    isHome: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({
    show: false,
  }),

  computed: {
    completeCount() {
      return this.steps.reduce((completeCount, item) => {
        if (item.complete) {
          return completeCount + 1
        }

        return completeCount
      }, 0)
    },

    stepText() {
      if (this.completeCount > 0) {
        const uncompletedCount = this.steps.length - this.completeCount
        return `${uncompletedCount} steps left`
      }
      return `${this.steps.length} steps`
    },

    setupProgress() {
      return parseInt(
        ((this.completeCount / this.steps.length) * 100).toFixed(2)
      )
    },

    completed() {
      return this.setupProgress === 100
    },
  },

  methods: {
    goToRouteAndElement(routeAndElement) {
      EventBus.$emit('goToRouteAndElement', routeAndElement)
    },

    hide() {
      this.show = false
    },

    toggle() {
      this.show = !this.show
    },
  },
}
</script>

<style>
.homeProgressCard {
  border-radius: 6px;
  overflow: hidden;
}
.homeProgressCard__header {
  padding: 8px 10px;
}
.homeProgressCard__bar {
  width: 100%;
  background-color: rgba(43, 199, 105, 0.2);
}
.homeProgressCard__header__count {
  padding: 6px 10px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  font-size: 12px;
  line-height: 15px;
  color: rgba(48, 51, 61, 0.7);
}
.homeProgressCard__header__arrow {
  transition: all 0.3s;
}
button.homeProgressCard__header__arrow:focus::after,
button.homeProgressCard__header__arrow:hover::after {
  content: none !important;
}
.homeProgressCard__header__arrow-active {
  transform: rotate(180deg);
}
.homeProgressCard__body {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.homeProgressCard__step {
  border: 1px solid transparent;
  border-radius: 6px;
  transition: all 0.3s;
}
.homeProgressCard__step:hover {
  border: 1px solid #3564ff;
}
</style>
