import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#3564FF',
        accent: '#3564FF',
        success: '#49a665',
        danger: '#FE5461',
        warning: '#FFBF05',
        darkblue: '#1402FF',
        black: '#30333D',
      },
    },
  },
  icons: {
    iconfont: 'mdi',
  },
})
