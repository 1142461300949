export const bootIntercom = (user, board) => {
  window.Intercom('boot', {
    api_base: 'https://api-iam.intercom.io',
    app_id: 'hw473otp',
    name: `${user.firstName} ${user.lastName}`,
    email: user.email,
    company: {
      id: board.id,
      name: board.name,
    },
  })
}

export const updateIntercom = (user) => {
  if (process.env.NODE_ENV !== 'development') {
    window.Intercom('update', {
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
    })
  }
}
